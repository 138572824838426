// import { useEffect } from 'react';
// import { connect } from "react-redux";
// import { enableScroll, disableScroll } from '../../Common';
// import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
// import { getSvg } from '../../utils/getSvg';
// import './OptionsMessagePopup.css';

// const OptionsMessagePopup = ({
//   currentRestaurantModeIconsStyle,
//   open, 
//   setOpen, 
//   messageTitle, 
//   messageText, 
//   optionOneText, 
//   optionTwoText, 
//   cancel, 
//   cancelText, 
//   optionOneCb, 
//   optionTwoCb, 
//   optionTwoStyle, 
//   language,
//   type="info"
//  }) => {

//   const handleCancel = () => {
//     setOpen(false);
//   };

//   // useEffect(() => {
//   //   if (open) {
//   //     document.body.style.overflowY = "hidden";
//   //   } else {
//   //     document.body.style.overflowY = "scroll";
//   //   }
//   //   return () => {
//   //     document.body.style.overflowY = "auto";
//   //   };
//   // }, [open]);

//   useEffect(() => {
//     if(open) {
//       disableScroll();
//     } else {
//       enableScroll();
//     }
//   }, [open])

//   return (
//     (open && 
//       <div className={`options-message-popup-page-wrapper ${language === "arabic" ? "rtl" : ""}`}>
//         <div className={`options-message-popup-container ${type}`}>
//           {/* <div className='options-message-popup-title'>
//             {messageTitle}
//           </div>
//           <div className='options-message-popup-text'>
//             {messageText}
//           </div> */}

//           <div className='options-message-popup-texts-container'>
//              <div className="options-message-popup-icon">
//             {type === "info" ? (
//               getSvg(`infoIcon${currentRestaurantModeIconsStyle}`, {color:"var(--main-color)"},
//               {width:"20px", height: "20px"}
//               )
//             ) : type === "warning" ? (
//               getSvg(`warningIcon${currentRestaurantModeIconsStyle}`, {color:"var(--waiting-color)"}, {width:"20px", height: "20px"})            ) 
//               : type === "error" ? (
//               <NotificationImportantOutlinedIcon className="confirm-popup-icon" />
//             ) : null}
//             </div>
//           <div className='options-message-popup-texts'>
//             <div className='options-message-popup-title'>{messageTitle}</div>
//              <div className='options-message-popup-text'>
//             {messageText}
//           </div>
//           </div> 
//           </div>
         
//           <div className='options-message-popup-btns'>
//             <button className='options-message-popup-btn-option-one' onClick={()=>{optionOneCb()}}>
//               {optionOneText}
//             </button>
//             {optionTwoText && (
//               <button className={`${optionTwoStyle && optionTwoStyle === "normal" ? "options-message-popup-btn-option-two-normal" : "options-message-popup-btn-option-two"}`}onClick={()=>{optionTwoCb()}}>
//               {optionTwoText}
//             </button>
//             )}
//             {!((cancel && cancel==="no-cancel")) && (
//             <button className='options-message-popup-btn-no' onClick={handleCancel}>
//               {cancelText}
//             </button>
//             )}
//           </div>
//         </div>
//       </div>
//     )
//   );
// };

// const mapStateToProps = (state) => ({
//   language: state.language.language,
//   currentRestaurantModeIconsStyle: state.designs.currentRestaurantModeIconsStyle,

// });

// export default connect(mapStateToProps, null)(OptionsMessagePopup);

import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { enableScroll, disableScroll } from "../../Common";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import { getSvg } from "../../utils/getSvg";
import "./OptionsMessagePopup.css";

const OptionsMessagePopup = ({
  currentRestaurantModeIconsStyle,
  open,
  setOpen,
  messageTitle,
  messageText,
  optionOneText,
  optionTwoText,
  cancel,
  cancelText,
  optionOneCb,
  optionTwoCb,
  optionTwoStyle,
  language,
  type = "info",
}) => {
  const popupRef = useRef(null); // Popup container reference
  const triggerRef = useRef(null); // Reference for the element that opened the popup

  const handleCancel = () => {
    setOpen(false);
  };

  // Save the currently focused element (trigger) before opening
  useEffect(() => {
    if (open) {
      triggerRef.current = document.activeElement; // Save the currently focused element
      disableScroll();
    } else {
      enableScroll();
      if (triggerRef.current) {
        triggerRef.current.focus(); // Restore focus to the trigger
      }
    }
  }, [open]);

  // Trap focus within the popup
  useEffect(() => {
    if (open) {
      const popupElement = popupRef.current;

      // Get all focusable elements inside the popup
      const focusableElements = popupElement.querySelectorAll(
        "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const handleFocusTrap = (e) => {
        if (e.key === "Tab") {
          if (e.shiftKey && document.activeElement === firstElement) {
            // Shift+Tab: move focus to last element
            e.preventDefault();
            lastElement.focus();
          } else if (!e.shiftKey && document.activeElement === lastElement) {
            // Tab: move focus to first element
            e.preventDefault();
            firstElement.focus();
          }
        }
      };

      popupElement.addEventListener("keydown", handleFocusTrap);

      // Automatically focus the first button when the popup opens
      firstElement.focus();

      return () => {
        popupElement.removeEventListener("keydown", handleFocusTrap);
      };
    }
  }, [open]);

  return (
    open && (
      <div
        ref={popupRef}
        className={`options-message-popup-page-wrapper ${
          language === "arabic" ? "rtl" : ""
        }`}
      >
        <div className={`options-message-popup-container ${type}`}>
          <div className="options-message-popup-texts-container">
            <div className="options-message-popup-icon">
              {type === "info" ? (
                getSvg(
                  `infoIcon${currentRestaurantModeIconsStyle}`,
                  { color: "var(--main-color)" },
                  { width: "20px", height: "20px" }
                )
              ) : type === "warning" ? (
                getSvg(
                  `warningIcon${currentRestaurantModeIconsStyle}`,
                  { color: "var(--waiting-color)" },
                  { width: "20px", height: "20px" }
                )
              ) : type === "error" ? (
                <NotificationImportantOutlinedIcon className="confirm-popup-icon" />
              ) : null}
            </div>
            <div className="options-message-popup-texts">
              <div className="options-message-popup-title">{messageTitle}</div>
              <div className="options-message-popup-text">{messageText}</div>
            </div>
          </div>

          <div className="options-message-popup-btns">
            <button
              className="options-message-popup-btn-option-one"
              onClick={() => {
                optionOneCb();
              }}
            >
              {optionOneText}
            </button>
            {optionTwoText && (
              <button
                className={`${
                  optionTwoStyle && optionTwoStyle === "normal"
                    ? "options-message-popup-btn-option-two-normal"
                    : "options-message-popup-btn-option-two"
                }`}
                onClick={() => {
                  optionTwoCb();
                }}
                tabIndex={0} // Ensure it is focusable
              >
                {optionTwoText}
              </button>
            )}
            {!(cancel && cancel === "no-cancel") && (
              <button
                className="options-message-popup-btn-no"
                onClick={handleCancel}
              >
                {cancelText}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  language: state.language.language,
  currentRestaurantModeIconsStyle: state.designs.currentRestaurantModeIconsStyle,
});

export default connect(mapStateToProps, null)(OptionsMessagePopup);
